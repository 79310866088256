import React, { PropsWithChildren } from "react";

interface IProps {}

export const Footer = (props: PropsWithChildren<IProps>) => {
    const { children } = props;
    return (
        <footer>
            © {new Date().getFullYear()} Like a Photon Creative | <a href={"https://www.fairyworldquest.com/privacy-policy"}>Privacy Policy</a> | <a href={"https://www.fairyworldquest.com/terms-and-conditions"}>Terms &amp; Conditions</a>
            {children}
        </footer>
    );
};
