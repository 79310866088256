import React, { PropsWithChildren } from "react";
import "../scss/index.scss";
import { Footer } from "./Footer";
import { Title } from "./Title";

interface IProps {
    label: string;
}

export const Layout = (props: PropsWithChildren<IProps>) => {
    const { label, children } = props;

    return (
        <>
            <div className={"container"}>
                <Title label={label} />
                {children}
                <Footer />
            </div>
        </>
    );
};
