import React, { useState } from "react";
import { DownloadIcons } from "../components/DownloadIcons";
import { Layout } from "../components/Layout";
import { Referrals } from "../components/Referrals";
import { SEO } from "../components/SEO";
import { SignUpForm } from "../components/SignUpForm";
import { api } from "../lib/api";

const Page = () => {
    const [sentEmailAddress, setSendEmailAddress] = useState<string | undefined>("");

    return (
        <>
            <SEO title={"Invite your friends"} lang={"en"} meta={[]} />
            <Layout label={"Match 3 City Building Game"}>
                <DownloadIcons playStoreUrl={"https://play.google.com/store/apps/details?id=com.likeaphoton.tfsc.twt.match3.slice"} appStoreUrl={"https://apps.apple.com/us/app/fairy-world-quest/id1484760100?ls=1"} />
                <section className={"section brown"}>
                    <h2 className={"has-text-centered"}>Invite your friends to play the brand new Fairy World Quest mobile game!</h2>
                </section>
                <SignUpForm
                    api={api}
                    onSent={({ sendingEmailAddress }) => {
                        setSendEmailAddress(sendingEmailAddress);
                    }}
                />
                {sentEmailAddress ? <Referrals api={api} emailAddress={sentEmailAddress} /> : ""}
            </Layout>
        </>
    );
};

export default Page;
