import React, { PropsWithChildren, useState } from "react";

interface IProps {
    onChange: (newValue: string) => void;
    type?: string;
    initialValue?: string;
    classNames?: string[];
    placeholder?: string;
}

export const BoundTextField = (props: PropsWithChildren<IProps>) => {
    const { onChange, type, initialValue, classNames, placeholder } = props;

    const [value, setValue] = useState(initialValue || "");

    const fullClassNames = (classNames || []).concat("input");

    return (
        <div className={"control"}>
            <input
                type={type || "text"}
                placeholder={placeholder}
                className={fullClassNames.join(" ")}
                onChange={(event) => {
                    const newValue = event.currentTarget.value;
                    setValue(newValue);
                    onChange(newValue);
                }}
                value={value}
            />
        </div>
    );
};
