import React, { PropsWithChildren, SyntheticEvent, useEffect, useState } from "react";
import { IAPI } from "../../common/Interfaces/IAPI";
import { BoundTextField } from "./BoundTextField";
import { BoundValidatedTextField, notEmpty } from "./BoundValidatedTextField";
import { Label } from "./Label";

interface IProps {
    api: IAPI;
    onSent: (payload: IPayload) => void;
}

interface IPayload {
    sendingEmailAddress: string;
    firstName: string;
    surname: string;
    receivingEmailAddresses: string[];
}

interface IResponse {
    values: IPayload;
}

export const SignUpForm = (props: PropsWithChildren<IProps>) => {
    const { api, onSent } = props;
    const [sendingEmailAddress, setSendingEmailAddress] = useState("");
    const [firstName, setFirstName] = useState("");
    const [surname, setSurname] = useState("");
    const [receivingEmailAddresses, setReceivingEmailAddresses] = useState([""]);
    const [isSubmittable, setIsSubmittable] = useState(false);

    const onSubmit = async (event: SyntheticEvent) => {
        event.preventDefault();

        const registerResult = await api.post<IResponse>("register", {
            sendingEmailAddress,
            firstName,
            surname,
            receivingEmailAddresses: receivingEmailAddresses.filter((candidate) => {
                return candidate.length > 0;
            }),
        });

        setReceivingEmailAddresses([""]);

        onSent(registerResult.values);
    };

    const renderReceiver = (index: number) => {
        const receivingEmailAddress = index < receivingEmailAddresses.length ? receivingEmailAddresses[index] : "";
        return (
            <Label key={index}>
                <BoundTextField
                    onChange={(newValue) => {
                        receivingEmailAddresses[index] = newValue;
                        setReceivingEmailAddresses([...receivingEmailAddresses]);
                    }}
                    placeholder={`Friend #${index + 1}`}
                    type={"email"}
                    initialValue={receivingEmailAddress}
                />
            </Label>
        );
    };

    useEffect(() => {
        let newIsSubmittable = true;
        if (sendingEmailAddress.length === 0) {
            newIsSubmittable = false;
        }
        if (firstName.length === 0) {
            newIsSubmittable = false;
        }
        if (surname.length === 0) {
            newIsSubmittable = false;
        }
        if (
            receivingEmailAddresses.filter((candidate) => {
                return candidate.length > 0;
            }).length === 0
        ) {
            newIsSubmittable = false;
        }
        setIsSubmittable(newIsSubmittable);
    }, [sendingEmailAddress, firstName, surname, receivingEmailAddresses]);

    return (
        <section className={"section"}>
            <h2>Send invitations</h2>
            <form onSubmit={onSubmit}>
                <Label>
                    <BoundValidatedTextField placeholder={"Your email address"} onChange={setSendingEmailAddress} type={"email"} initialValue={sendingEmailAddress} validators={[notEmpty("Email address must be provided.")]} />
                </Label>
                <Label>
                    <BoundValidatedTextField placeholder={"Your first name"} onChange={setFirstName} type={"text"} initialValue={firstName} validators={[notEmpty("First name must be provided.")]} />
                </Label>
                <Label>
                    <BoundValidatedTextField placeholder={"Your surname"} onChange={setSurname} type={"text"} initialValue={surname} validators={[notEmpty("Surname must e provided")]} />
                </Label>
                <h3>Invitations</h3>
                <p>Enter the email addresses of the friends you want to invite!</p>
                {receivingEmailAddresses.map((receivingEmailAddress, index) => {
                    return renderReceiver(index);
                })}
                {receivingEmailAddresses[receivingEmailAddresses.length - 1].length > 0 ? renderReceiver(receivingEmailAddresses.length) : ""}
                <button disabled={!isSubmittable} className="button is-dark" type={"submit"}>
                    Register
                </button>
            </form>
        </section>
    );
};
