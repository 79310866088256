import React, { PropsWithChildren, useEffect, useState } from "react";
import { IAPI } from "../../common/Interfaces/IAPI";
import { IMember } from "../../common/Interfaces/IMember";

interface IProps {
    api: IAPI;
    emailAddress: string;
}

interface IResponse {
    members: IMember[];
}

export const Referrals = (props: PropsWithChildren<IProps>) => {
    const { api, emailAddress } = props;
    const [members, setMembers] = useState<IMember[]>([]);

    const fetchMembers = async () => {
        const response = await api.get<IResponse>("membersByReferrer", {
            referringEmailAddress: emailAddress,
        });

        setMembers(response.members);
    };

    useEffect(() => {
        fetchMembers();
    }, [emailAddress]);

    return (
        <section className={"section"}>
            <h2>Your invitations</h2>
            <p>Here&rsquo;s who you have invited so far!</p>
            <ul>
                {members.map((member) => {
                    return <li key={member.email_address}><strong>{member.email_address}</strong></li>;
                })}
            </ul>
        </section>
    );
};
