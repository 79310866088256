import React, { PropsWithChildren, useState } from "react";
import { BoundTextField } from "./BoundTextField";
import pEachSeries from "p-each-series";

type Validator = (newValue: string) => Promise<void>;

interface IProps {
    onChange: (newValue: string) => void;
    type?: string;
    initialValue?: string;
    validators: Validator[];
    placeholder?: string;
}

export const notEmpty = (errorMessage: string) => {
    return async (newValue: string) => {
        if (newValue.length === 0) {
            throw new Error(errorMessage);
        }
    };
};

export const BoundValidatedTextField = (props: PropsWithChildren<IProps>) => {
    const { onChange: parentOnChange, type, initialValue, validators, children, placeholder } = props;

    const [error, setError] = useState<Error | undefined>(undefined);

    const onChange = async (newValue: string) => {
        try {
            await pEachSeries(validators, (validator) => {
                return validator(newValue);
            });
            setError(undefined);
            parentOnChange(newValue);
        } catch (error_) {
            setError(error_);
        }
    };

    return (
        <>
            <BoundTextField placeholder={placeholder} classNames={error ? ["is-danger"] : []} onChange={onChange} type={type} initialValue={initialValue}>
                {children}
            </BoundTextField>
            {error ? <p className={"help is-danger"}>{error.message}</p> : ""}
        </>
    );
};
